import { useState } from "react"
import { HiCalendar } from "react-icons/hi"
import { LastSubtitle, SubtitlePostItem } from "../../typing"
import PosterCard from "../poster-card"
import { LastAddSubtitle } from "./last-add-subtitle"

interface Props {
    series: SubtitlePostItem[]
    movies: SubtitlePostItem[]
}
export const HomeLastAdd = ({ series, movies }: Props) => {
    const [activeTab, setActiveTab] = useState(1)
    return (
        <div className="px-4 pt-4">
            <div className="mb-4">
                <ul className="m-0 p-0 list-none text-sm flex flex-nowrap overflow-x-auto space-x-2">
                    <li
                        className={`${
                            activeTab == 1
                                ? "bg-sub-brand text-white"
                                : "bg-sub-white-gray dark:bg-sub-gray"
                        } flex-none inline-block px-6 py-3 cursor-pointer rounded text-lg font-semibold`}
                        onClick={() => setActiveTab(1)}
                    >
                        Series
                    </li>
                    <li
                        className={`${
                            activeTab == 2
                                ? "bg-sub-brand text-white"
                                : "bg-sub-white-gray dark:bg-sub-gray"
                        } flex-none inline-block px-6 py-3 cursor-pointer rounded text-lg font-semibold`}
                        onClick={() => setActiveTab(2)}
                    >
                        Movies
                    </li>
                </ul>
            </div>
            {activeTab == 1 && (
                <div className="grid lg:grid-cols-5 sm:grid-cols-3 md:grid-cols-4 grid-cols-2 lg:gap-6 gap-4">
                    {series.map((item) => {
                        return <PosterCard key={item.id} item={item} />
                    })}
                </div>
            )}
            {activeTab == 2 && (
                <div className="grid lg:grid-cols-5 sm:grid-cols-3 md:grid-cols-4 grid-cols-2 lg:gap-6 gap-4">
                    {movies.map((item) => {
                        return <PosterCard key={item.id} item={item} />
                    })}
                </div>
            )}
        </div>
    )
}
