import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import { cleanTitle } from "../lib/helper"
import { WPLoader } from "../lib/image-loader"
import { permalink } from "../lib/util"
import { ImdbTopItems, SubtitlePostItem } from "../typing"

function cn(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

export default function PosterCardImDb({ item }: { item: ImdbTopItems }) {
    const [isLoading, setLoading] = useState(true)
    const [imgSrc, setImgSrc] = useState(item.poster)

    return (
        <div className="group flex-shrink-0 relative overflow-hidden rounded-sm shadow-xl shadow-gray-300 dark:shadow-hitam">
            <Link href={`/subtitle-${item.permalink}`}>
                <a>
                    <div className="aspect-w-7 aspect-h-10 w-full overflow-hidden rounded-md bg-gray-800">
                        <Image
                            priority={true}
                            alt={item.title}
                            src={imgSrc}
                            layout="fill"
                            objectFit="cover"
                            className={cn(
                                "duration-700 ease-in-out opacity-70 group-hover:opacity-95 group-hover:scale-110",
                                isLoading
                                    ? "scale-110 blur-2xl grayscale"
                                    : "scale-100 blur-0 grayscale-0",
                            )}
                            onLoadingComplete={() => setLoading(false)}
                            onError={() => {
                                setImgSrc(
                                    "https://i2.wp.com/subsc.my.id/static/images/no-thumb.png",
                                )
                            }}
                            sizes="30vw"
                            loader={WPLoader}
                        />
                    </div>
                </a>
            </Link>
            <div className="absolute bottom-0 bg-gradient-to-t dark:from-gray-900 from-gray-900 w-full transform pb-5 flex flex-col space-y-1 py-2 px-4">
                <Link href={`/subtitle-${item.permalink}`}>
                    <a>
                        <div className="text-base font-semibold text-yellow-300">
                            {cleanTitle(item.title)}
                        </div>
                    </a>
                </Link>
                <div className="text-xs text-white flex items-center space-x-2">
                    {item.total} Subtitle
                </div>
            </div>
        </div>
    )
}
