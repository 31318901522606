import React from "react"
import { Ads728, AdsLayout, AdsLayout2 } from "./ads-item"

function AdsList() {
    return (
        <AdsLayout>
            <Ads728
                src="/images/ad-space.png"
                href="/pasang-iklan"
                title="Ads"
                className="col-span-1"
                expired="1 agustus 2024"
                h={85}
            />
            <Ads728
                src="/images/ad-space.png"
                href="/pasang-iklan"
                title="Ads"
                className="col-span-1"
                expired="1 agustus 2024"
                h={85}
            />
        </AdsLayout>
    )
}

export default AdsList
