import Image from "next/image"
import { WPLoader } from "../../lib/image-loader"

interface Props {
    src: string
    href: string
    title: string
    w?: number
    h?: number
    className?: string
    expired?: string
}

export const Ads728 = ({
    src,
    href,
    title,
    w = 728,
    h = 90,
    className = "",
    expired,
}: Props) => {
    return (
        <div className={`${className}  mb-1 lg:mb-0`}>
            <a
                href={href}
                target="_blank"
                rel="nofollow"
                title={title}
                className="flex items-center justify-center"
            >
                <Image
                    src={src}
                    width={w}
                    height={h}
                    quality={70}
                    className="rounded-sm"
                    loader={WPLoader}
                />
            </a>
        </div>
    )
}

interface PropsAds {
    children: React.ReactNode
}

export const AdsLayout: React.FC<PropsAds> = ({ children }) => {
    return (
        <div className="lg:grid lg:grid-cols-2 grid-cols-1 gap-1 my-2">
            {children}
        </div>
    )
}
export const AdsLayout2: React.FC<PropsAds> = ({ children }) => {
    return (
        <div className="lg:grid lg:grid-cols-1 grid-cols-1 gap-2">
            {children}
        </div>
    )
}
