import Link from "next/link"
import React from "react"
import { FaBullhorn } from "react-icons/fa"

function Info() {
    return (
        <div className="p-4">
            <div className="flex items-center">
                <FaBullhorn className="text-red-500 mr-2" />
                Penambahan subtitle baru!!
            </div>
            <div className="text-sm dark:text-gray-400 text-gray-600">
                Subtitle baru sudah ditambahkan, gunakan halaman{" "}
                <Link href="/pencarian">
                    <a className="dark:text-white text-gray-800 font-semibold">
                        PENCARIAN
                    </a>
                </Link>{" "}
                untuk mencari lebih lengkap semua subtitle terbaru. Fitur ini
                masih dalam tahap beta test, oleh karena itu fitur, data, dan
                hal lainnya mungkin masih bermasalah serta dapat berubah
                nantinya.
            </div>
        </div>
    )
}

export default Info
