import { NextSeo } from "next-seo"
// import Link from "next/link"
// import { FaSearchPlus } from "react-icons/fa"
// import { HiCalendar } from "react-icons/hi"
import { ImDbTop } from "../components/home/imdb-top"
import { HomeLastAdd } from "../components/home/last-add"
// import { LastAddSubtitle } from "../components/home/last-add-subtitle"
import { Page } from "../components/layout/page"
import { getLatestAdd, getImdbTop } from "../lib/helper"
import { lastAddSUbtitle } from "../lib/helper-post"
import { LastSubtitle, SubtitlePostItem } from "../typing"
import AdsList from "../components/layout/ads-list"
import Info from "../components/info"
import { SubSourcePopular } from "../lib/subsource"

interface Props {
    seriesData: SubtitlePostItem[]
    moviesData: SubtitlePostItem[]
    topSubtitle?: any
    lastSubtitle?: LastSubtitle
}
const Home = ({ seriesData, moviesData, topSubtitle, lastSubtitle }: Props) => {
    return (
        <>
            <NextSeo
                title="Subscene.id - Download Subtitle Movie dan Series Berbahasa Indonesia"
                titleTemplate="%s"
                description="Download subtitle berbahasa indonesia terlengkap di subsc.my.id. Download subtitle indonesia seris dan movie korea, usa, thailand, jepang, china berbahasa indonesia"
            />
            <Page className="page">
                <div className="px-4 pt-4">
                    <AdsList />
                </div>
                <Info />
                {/* <div className="px-4 py-4">
                    <div className="flex items-center justify-between">
                        <h2 className="text-2xl font-semibold mb-2 flex items-center space-x-1">
                            <HiCalendar className="mr-2 text-brand-blue dark:text-brand" />{" "}
                            Update
                            <span className="text-brand-blue dark:text-brand">
                                Terbaru
                            </span>
                        </h2>
                        <span>
                            <Link href="/allpost">
                                <a className="px-4 py-2 rounded flex items-center text-white bg-green-500 hover:bg-green-600 border-0 shadow-md">
                                    <FaSearchPlus className="mr-1" /> Show More
                                </a>
                            </Link>
                        </span>
                    </div>
                    {lastSubtitle && <LastAddSubtitle data={lastSubtitle} />}
                </div> */}
                <HomeLastAdd series={seriesData} movies={moviesData} />
                {topSubtitle.length >= 1 && <ImDbTop data={topSubtitle} />}
            </Page>
        </>
    )
}

export async function getStaticProps() {
    try {
        const seriesFilter = {
            type: {
                $eq: "series",
            },
        }
        const movieFilter = {
            type: {
                $eq: "movie",
            },
        }
        // const seriesData = await getLatestAdd(seriesFilter)
        // const moviesData = await getLatestAdd(movieFilter)
        const [seriesData, moviesData, imdbTop, lastSubtitle, popularData] =
            await Promise.all([
                getLatestAdd(seriesFilter),
                getLatestAdd(movieFilter),
                getImdbTop(),
                lastAddSUbtitle(),
                SubSourcePopular(),
            ])
        // console.log(imdbTop)
        return {
            props: {
                status: true,
                seriesData: seriesData.data,
                moviesData: moviesData.data,
                topSubtitle: popularData ? popularData.items : false,
                lastSubtitle: lastSubtitle ?? null,
            },
            revalidate: 3600,
        }
    } catch (error) {
        console.log(error)
    }
}

export default Home
