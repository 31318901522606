import { AiTwotoneFire } from "react-icons/ai"
import { ImdbTopItems } from "../../typing"
import PosterCardImDb from "../poster-card-imdb"

export const ImDbTop = ({ data }: { data?: ImdbTopItems | any }) => {
    return (
        <div className="px-4 my-6">
            <h2 className="text-2xl font-semibold mb-4 flex items-center space-x-1">
                <AiTwotoneFire className="mr-2 text-brand-blue dark:text-brand" />{" "}
                Subtitle
                <span className="text-brand-blue dark:text-brand">Populer</span>
            </h2>
            <div className="grid lg:grid-cols-5 sm:grid-cols-3 md:grid-cols-4 grid-cols-2 lg:gap-6 gap-4">
                {data?.map((item: ImdbTopItems) => {
                    return <PosterCardImDb key={item.permalink} item={item} />
                })}
            </div>
        </div>
    )
}
