import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import { cleanTitle } from "../lib/helper"
import { WPLoader } from "../lib/image-loader"
import { permalink, stringToArray } from "../lib/util"
import { SubtitlePostItem } from "../typing"
import siteConfig from "../constants/site-config.json"

function cn(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

export default function PosterCard({ item }: { item: SubtitlePostItem }) {
    const [isLoading, setLoading] = useState(true)
    const [imgSrc, setImgSrc] = useState(item.attributes.poster)

    function renderGenre() {
        const genres = stringToArray(item.attributes.genre || "")
        return genres.slice(0, 2).map((genre) => {
            return (
                <Link key={genre} href={`/genre/${permalink(genre)}`}>
                    <a
                        className="truncate bg-sub-gray rounded-sm px-1"
                        title={`Genre ${genre}`}
                    >
                        {genre}
                    </a>
                </Link>
            )
        })
    }

    function renderCountry() {
        const countries = stringToArray(item.attributes.country || "")
        return countries.slice(0, 1).map((country) => {
            return (
                <Link key={country} href={`/country/${permalink(country)}`}>
                    <a title={`Negara ${country}`}>{country}</a>
                </Link>
            )
        })
    }

    return (
        <div className="group flex-shrink-0 relative overflow-hidden rounded-sm shadow-xl shadow-gray-300 dark:shadow-hitam">
            <Link href={`/subtitle-${item.attributes.permalink}`}>
                <a
                    title={`${cleanTitle(
                        item.attributes.title,
                    )} Subtitle Indonesia`}
                >
                    <div className="aspect-w-7 aspect-h-10 w-full overflow-hidden rounded-md bg-gray-800">
                        <Image
                            priority={true}
                            alt={`${cleanTitle(
                                item.attributes.title,
                            )} Subtitle Indonesia`}
                            title={`${cleanTitle(
                                item.attributes.title,
                            )} Subtitle Indonesia`}
                            src={imgSrc}
                            layout="fill"
                            objectFit="cover"
                            className={cn(
                                "duration-700 ease-in-out opacity-70 group-hover:opacity-95 group-hover:scale-110",
                                isLoading
                                    ? "scale-110 blur-2xl grayscale"
                                    : "scale-100 blur-0 grayscale-0",
                            )}
                            onLoadingComplete={() => setLoading(false)}
                            onError={() => {
                                setImgSrc(siteConfig.site_no_image)
                            }}
                            sizes="30vw"
                            loader={WPLoader}
                        />
                    </div>
                </a>
            </Link>
            {item.attributes?.newSubtitle && (
                <div className="top-1 absolute right-0">
                    <span className="new">NEW</span>
                </div>
            )}
            <div className="absolute bottom-0 bg-gradient-to-t dark:from-gray-900 from-gray-900 w-full transform pb-5 flex flex-col space-y-1 py-2 px-4">
                <Link href={`/subtitle-${item.attributes.permalink}`}>
                    <a
                        title={`${cleanTitle(
                            item.attributes.title,
                        )} Subtitle Indonesia`}
                    >
                        <div className="text-base font-semibold text-yellow-300">
                            {cleanTitle(item.attributes.title)}
                        </div>
                    </a>
                </Link>
                <div className="text-xs text-white flex items-center">
                    {item.attributes.year}
                    <span className="mx-1 text-xl font-bold mb-1">
                        &middot;
                    </span>
                    <span>{renderCountry()}</span>
                </div>
                <div className="flex items-center text-xs space-x-2 text-white">
                    {renderGenre()}
                </div>
            </div>
        </div>
    )
}
